<script>
export default {
  name: "App",
  components: {},
};
</script>

<template>
  <router-view></router-view>
</template>

<style lang="scss">
:root {
  --main-color: #248bae;
  --sub-color: #f4c166;
  --bg-color: #4e7380;
  /* popper style */
  --popper-theme-background-color: #4e7380;
  --popper-theme-background-color-hover: #4e7380;
  --popper-theme-text-color: #ffffff;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 12px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

#app {
  overflow: hidden;
  letter-spacing: 1px;
  font-family: "Lato", sans-serif;
}

.banner {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  object-position: bottom;
  position: relative;
  z-index: -1;
  animation: scale 2.5s ease-in-out;

  @media (max-width: 991px) {
    height: 70vw;
  }
}

@keyframes scale {
  0% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.underline {
  width: 100px;
  height: 3px;
  display: block;
  margin: 30px 0;
  background: linear-gradient(
    to left,
    var(--main-color) 50%,
    var(--sub-color) 50%
  );
}

.btn-box {
  padding-top: 9px;

  button {
    padding: 3px 8px;
    border-radius: 5px;
    transition: all 0.3s;
    &:hover {
      opacity: 0.8;
    }

    &:first-child {
      margin-right: 10px;
      color: #fff;
      background-color: var(--bg-color);
      border: 1px solid transparent;
    }

    &:last-child {
      color: var(--bg-color);
      background-color: #fff;
      border: 1px solid var(--bg-color);
    }
  }
}

.card-item {
  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 50px;
    background-color: var(--bg-color);
    transition: all 0.3s;
    border-radius: 5px;
    border-bottom: none !important;
    &:hover {
      opacity: 0.9;
      border-bottom: none !important;
    }
    &:first-child {
      color: #fff !important;
      background-color: var(--bg-color) !important;
    }
    &:last-child {
      color: var(--bg-color) !important;
      background-color: var(--sub-color) !important;
    }
  }
  section {
    h2 {
      font-size: 1.8rem;
      font-weight: bold;
    }
    p {
      margin: 0;
      max-width: 700px;
      font-weight: 400;
    }
    small {
      display: block;
      margin: 15px 0;
    }
  }
  i {
    font-size: 3rem;
  }
  i:first-child {
    color: #fff;
  }
}
</style>
